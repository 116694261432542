<template>

<com-content :inner="true">

	<div class="testimonial">

		<div class="testimonial-image"></div>
		
		<div class="testimonial-text">

			<i class="fa-solid fa-quote-left"></i>	

			<p>CardboardEvents is the most visually appealing, robust and easy to use boardgame convention software on the market. Don't hesitate on this one.</p>

			<small>Dave Millette<br/>Event Organizer, Dice Tower West 2023</small>

		</div>

	</div>

</com-content>

</template>

<script>

export default {
	
	components: {

		'com-content': () => import('./../common/Content'),

	}

}

</script>

<style scoped>

.testimonial {
	padding: 40px;
	border-radius: 10px;
	border: 1px solid #eee;
    box-shadow: 0 1px 2px #ccc;
	background-color: #fff;
	display: flex;
	align-items: center;
}

.is-mobile .testimonial {
	padding: 0px 10px;
	border: 0px;
	box-shadow: none;
	flex-direction: column;
	margin-bottom: 40px;
	background-color: transparent;
}

.testimonial-image {
	width: 160px;
	flex-shrink: 0;
	border-radius: 50%;
	border: 10px solid #ffbe7a;
	height: 160px;
	background-position: 50% 50%;
	background-size: cover;
	background-image: url(https://static.wixstatic.com/media/c05de0_f353a33ed0ea482994f74f208189e142~mv2_d_1372_1474_s_2.gif);
}

.testimonial-text {
	flex-grow: 1;
	padding-left: 40px;
}

.is-mobile .testimonial-text {
	padding: 20px 0px 0px 0px;
	text-align: center;
}

.testimonial-text .fa-solid {
	font-size: 24px;
	color: #ffbe7a;
	margin-bottom: 4px;
}

.testimonial-text p {
	color: #444;
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
	margin-bottom: 20px;
}

.testimonial-text small {
	color: #666;
	font-size: 14px;
	line-height: 18px;
	font-weight: 300;
}

</style>